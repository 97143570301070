

.productTile {
    margin: 20px;
    width: 350px;
    height: 400px;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
    background-color: #FFF;
}

.imageHolder > img {
    height: 300px !important;
    object-fit: cover;
}