

.contactPane {
    background-color: #0F4B92 !important;
}


@keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
.animateFromLeft {
    animation: 1s ease-out 0s 1 slideInFromLeft;
}

@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
  
    to {
      opacity: 1;
      transform: none;
    }
  }
  
  .fadeInUp {
    animation: 1s ease-out 0s 1 fadeInUp;
  }