
@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
  
    to {
      opacity: 1;
      transform: none;
    }
  }
  
  .fadeInUp {
    animation: 1s ease-out 0s 1 fadeInUp;
  }
@keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
.animateFromLeft {
    animation: 1s ease-out 0s 1 slideInFromLeft;
}
.careerContainer {
    margin-top: 50px;
    padding-bottom: 0px !important;
}
.uploadContainer {
  display: flex;
  align-items: center;
    justify-content: space-between;
}

.jobListContainer {
    padding: 20px 0 !important;
    margin: 0 !important;
}

.jobList {
    display: flex;
    flex-wrap: wrap;
}

.jobItemLayout {
    width: 300px;
    height: 350px;
    margin: 20px;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
    background-color: #FFF;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.buttonHolderConfirm{
  margin-top: 50px;
  display: flex;
    place-content: space-around;
}
.confirmPopup{
  text-align: center;
  position: fixed;

  left: 50%;
right: 50%;
top: 50%;
bottom: 50%;
transform: translate(-50%, -50%);
 
  z-index: 9;
  background-color: white;
  width: 500px;
  height: fit-content;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 30px;
  border-radius: 10px;
}
.jobTitle {
    font-size: 20px;
    font-weight: 600;
}
.jobLocation {
    display: flex;
    align-items: center;
}
.jobDescription {
    font-size: 14px;
}
.jobAvailable {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hiringCover {
    max-height: 800px;
    overflow: hidden;
    
}
.adminTools {
  display: flex;
  justify-content: space-between;
}
.hiringCover > img {
    max-height: 1000px;
    width: 100%;
    height: auto;
    object-fit: cover;
}


.close-ribbon{
    position: absolute;
    margin-bottom: 330px;
    margin-left: 200px;
    z-index: 1;
    color: #F44336;
    font-size: 2em;
    cursor: pointer;
    width: 1.5em;
    line-height: 0.5em;
    -webkit-transition: line-height 0.5s;
    transition: line-height 0.5s;
  }
  .close-ribbon:after {
    content: "";
    font-size: 0.5em;
    position: absolute;
    height: 1.2em;
    border: 1.5em solid #F44336;
    z-index: -1;
    top: -1em;
    border-top-width: 1.5em;
    border-bottom-color: transparent;
    right: 0;
    -webkit-transition: height 0.5s;
    transition: height 0.5s;
    color: #F44336;
  }
  .close-ribbon:before {
    content: "";
    font-size: 0.5em;
    position: absolute;
    border-style: solid;
    border-color: transparent transparent #B71C1C transparent;
    top: -1em;
    right: 3em;
    border-width: 0 0 1em 0.7em;
    z-index: -1;
  }
  .close-ribbon:hover:after{
    border: 1.5em solid #EF5350;
    border-bottom-color: transparent;
    height: 1.6em;
    -webkit-transition: height 0.5s;
    transition: height 0.5s;
  }
  .close-ribbon:hover{
    line-height: 0.9em;
    color: #F44336;
    -webkit-transition: line-height 0.5s;
    transition: line-height 0.5s;
  }
  .btn-sendCV{
    font-size: 16px;
    height: 40px;
    display: flex !important;
    align-items: center !important;
    width: auto;
  }
  @media (max-width: 800px) {
    

    .btn-sendCV{
      font-size: 14px !important;
      height: 20px !important;
      display: flex;
      align-items: center;
      width: 200px;
    }
    .jobListContainer{
      margin-top: 0 !important;
    }
    
}
  