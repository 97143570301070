

.addContainer {
  position: fixed;

  left: 50%;
right: 50%;
top: 50%;
bottom: 50%;
transform: translate(-50%, -50%);
 
  z-index: 9;
  background-color: white;
  width: 500px;
  height: fit-content;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 30px;
  border-radius: 10px;
}

.addTitle {
    font-size: 20px;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}

.button-container{
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
}
input {
    width: 100%;
    margin: 20px 0 !important;
    border-radius: 10px !important;
}

@media (max-width: 800px) {
    

    .addContainer{
        width: 100% !important;
        height: 90% !important;
    }
    
}