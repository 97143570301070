

.headerLayout {
}
.web {
    display: block !important;
}
.mobile {
    display: none !important;
}

.responsiveImg {
    display: flex;
    align-items: center;
}
.logoImageTop > path {
    fill: #FFF;
}

.logoPlace {
    width: 250px !important;
    margin-left: 10px;
    margin: 6px 0 0 5px;
}
.newLogoPlace {
    width: 100px !important;
}

.welcomeuser {
    align-self: center;
    margin-right: 30px;
    font-weight: 600;
}

@media (max-width: 800px) {
    .nav-link {
        color: #0F4B92 !important;
    }
    .web {
        display: none !important;
    }
    .mobile {
        display: block !important;
    }
    .logoPlace {
        width: 150px !important;
    }
    .responsiveImg{
        padding: 0 !important;
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }
    .newLogoPlace {
        width: 60px !important;
    }
}

