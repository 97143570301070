@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
  
    to {
      opacity: 1;
      transform: none;
    }
  }
  
  .fadeInUp {
    animation: 1s ease-out 0s 1 fadeInUp;
  }
@keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
.animateFromLeft {
    animation: 1s ease-out 0s 1 slideInFromLeft;
}
.carouselContainer {
    max-height: 800px;
    overflow: hidden;
  
}
.mainImage {
    max-height: 800px;
    width: auto;
  height: auto;
  object-fit: cover;
    object-position: 50% 50% !important;
}
.carouselHolder {
    max-height: 800px;
    .carousel .slide {
        max-height: 800px;
    }
}
.carousel-inner {
    max-height: 800px;
}
.carousel-item {
    max-height: 800px;
}

.carousel-caption {
    max-height: 800px;
}
.productList{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    justify-content: space-between;
}
.image-slideshow-three {
    height: 700px;
    width: 100%;
    object-fit: cover;
    object-position: 70% 70%;
}

.carousel-item > img {
    
}
.btn-showMe:hover {
    font-weight: 600;
}

.workingHours {
    background-color: #FFF;
    height: 600px;
display: flex;
justify-content: space-around;

padding: 50px;
padding-top: 100px;

}

.working-hours {
    font-size: 60px;
    font-weight: 600;
}

.timeHolder {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
}
.actualTime{
    padding-left: 20px;
}
.logoLayover {
    height: 100%;
    display: flex;
    align-items: end;
    color: #555555;
    font-size: 28px;
    width: 100%;
    padding-bottom: 0px;
}

.image-working-layout {
    display: flex;
    align-items: baseline;
    flex-direction: column;
}
.careerButton {
    display: block;
}
.bg-theme{
    background-color: #0F4B92;
    margin-top: 0px !important;
}
.carousel-indicators {
    display: none !important;
}
.messageHead {
    font-weight: 600 !important;
    font-size: 80px !important;
    color: #f87337;
}
.messageContent {
    font-size: 40px !important;
    text-align: left;
    color: #0F4B92;
}


@media (min-width:320px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
    .messageHead {
        font-weight: 600;
        font-size: 20px !important;
    }
    .messageContent {
        font-size: 14px !important;
        text-align: left;
    }
    .timeHolder {
        font-size: 12px !important;
    }
    .working-hours {
        font-size: 20px !important;
    
    }
    .logoLayover {
        padding-bottom: 0px !important;
    } 

    .careerButton {
        display: none;
    }
    .image-slideshow-three {
        height: 300px;
    }
    .react-3d-carousel {
        height: 300px !important;
    }
    .mainImage {
        object-position: 70% 80%;
    }
}
@media (min-width:480px)  { /* smartphones, Android phones, landscape iPhone */
    .timeHolder {
        font-size: 14px !important;
    }
    .working-hours {
        font-size: 30px !important;
    
    }
    .messageHead {
        font-weight: 600 !important;
        font-size: 20px !important;
        color: #f87337;
    }
    .messageContent {
        font-size: 40px !important;
        text-align: left;
        color: #0F4B92;
    }
    .logoLayover {
        padding-bottom: 50px;
    } }
@media (min-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    .timeHolder {
        font-size: 14px !important;
    }
    .messageHead {
        font-weight: 600 !important;
        font-size: 30px !important;
        color: #f87337;
    }
    .messageContent {
        font-size: 20px !important;
        text-align: left;
        color: #0F4B92;
    }
    .working-hours {
        font-size: 30px !important;
    
    }
    .logoLayover {
        padding-bottom: 50px !important;
    }
    .careerButton {
        display: block;
    }
    .image-slideshow-three {
        height: 500px;
    }
    .react-3d-carousel {
        height: 500px !important;
    }
 }
@media (min-width:801px)  { 
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    .timeHolder {
        font-size: 20px !important;
    }
    .messageHead {
        font-weight: 600 !important;
        font-size: 40px !important;
        color: #f87337;
    }
    .messageContent {
        font-size: 30px !important;
        text-align: left;
        color: #0F4B92;
    }
    .working-hours {
        font-size: 40px !important;
    
    }
    .logoLayover {
        padding-bottom: 50px;
    }
    .careerButton {
        display: block;
    }
    .image-slideshow-three {
        height: 600px;
    }
    .react-3d-carousel {
        height: 600px !important;
    }
    
 }
@media (min-width:1025px) { 
    /* big landscape tablets, laptops, and desktops */
    .timeHolder {
        font-size: 20px !important;
    }
    .messageHead {
        font-weight: 600 !important;
        font-size: 40px !important;
        color: #f87337;
    }
    .messageContent {
        font-size: 30px !important;
        text-align: left;
        color: #0F4B92;
    }
    .working-hours {
        font-size: 60px !important;
    
    }
    .logoLayover {
        padding-bottom: 0px;
    }
    .careerButton {
        display: block;
    }
    .image-slideshow-three {
        height: 600px;
    }
    .react-3d-carousel {
        height: 600px !important;
    }
 }
@media (min-width:1281px) { /* hi-res laptops and desktops */
    .timeHolder {
        font-size: 20px !important;
    }
    .messageHead {
        font-weight: 600 !important;
        font-size: 60px !important;
        color: #f87337;
    }
    .messageContent {
        font-size: 40px !important;
        text-align: left;
        color: #0F4B92;
    }
    .working-hours {
        font-size: 60px !important;
    
    }
    .logoLayover {
        padding-bottom: 150px !important;
    }
    .careerButton {
        display: block;
    }
    .image-slideshow-three {
        height: 700px;
    }
    .react-3d-carousel {
        height: 700px !important;
    }
}

@media (min-width:2560px) { /* hi-res laptops and desktops */
    .timeHolder {
        font-size: 40px !important;
    }
    .working-hours {
        font-size: 100px !important;
    
    }
    .logoLayover {
        padding-bottom: 150px;
    }
    .careerButton {
        display: block;
    }
    .image-slideshow-three {
        height: 900px;
    }
    .react-3d-carousel {
        height: 900px !important;
    }
    .messageHead {
        font-weight: 600 !important;
        font-size: 80px !important;
        color: #f87337;
    }
    .messageContent {
        font-size: 50px !important;
        text-align: left;
        color: #0F4B92;
    }
}

@media (max-width: 400) {
    
    .timeHolder {
        font-size: 14px !important;
    }
    .workingHours {
        flex-direction: column;
        padding: 20px !important;
        font-size: 20px !important;
    
    }
    .workingImage {
        width: 100%;
    }
    .workingImage > img {
        width: 300px !important;
    }
    .carouselContainer {
        height: auto !important;
        overflow: hidden;
      
    }
    .carouselHolder {
        height: auto !important;
        .carousel .slide {
            height: auto !important;
        }
    }
    .carousel-inner {
        height: auto !important;
    }
    .carousel-item {
        height: auto !important;
    }
    
    .carousel-caption {
        height: auto !important;
    }
    
}

