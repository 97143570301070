

.messageContainer {
    position: fixed;
  bottom: 10%;
  right: 10%;
    z-index: 9;
    background-color: white;
    min-width: 30%;
    height: fit-content;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    height: 60px;
    display: flex;
    align-items: center;
    place-content: center;
    font-weight: 600;
}