/********** Template CSS **********/
:root {
    --primary: #3CB815;
    --secondary: #F65005;
    --light: #F7F8FC;
    --dark: #111111;
}

.back-to-top {
    position: fixed;
    display: none;
    right: 30px;
    bottom: 30px;
    z-index: 99;
}

.my-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
}

.py-6 {
    padding-top: 6rem;
    padding-bottom: 6rem;
}

.bg-icon {
    background-size: contain;
}


/*** Spinner ***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}


/*** Button ***/
.btn {
    font-weight: 500;
    transition: .5s;
}

.btn.btn-primary,
.btn.btn-secondary,
.btn.btn-outline-primary:hover,
.btn.btn-outline-secondary:hover {
    color: #FFFFFF;
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
}


/*** Navbar ***/
.fixed-top {
    transition: .5s;
}

.top-bar {
    height: 45px;
    border-bottom: 1px solid rgba(0, 0, 0, .07);
}

.navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
}

.navbar .navbar-nav .nav-link {
    padding: 25px 15px;
    color: #555555;
    font-weight: 500;
    outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
    color: var(--dark);
}

@media (max-width: 991.98px) {
    .navbar .navbar-nav {
        margin-top: 10px;
        border-top: 1px solid rgba(0, 0, 0, .07);
        background: #FFFFFF;
    }

    .navbar .navbar-nav .nav-link {
        padding: 10px 0;
    }
}

@media (min-width: 992px) {
    .navbar .nav-item .dropdown-menu {
        display: block;
        visibility: hidden;
        top: 100%;
        transform: rotateX(-75deg);
        transform-origin: 0% 0%;
        transition: .5s;
        opacity: 0;
    }

    .navbar .nav-item:hover .dropdown-menu {
        transform: rotateX(0deg);
        visibility: visible;
        transition: .5s;
        opacity: 1;
    }
}



.page-header {
    padding-top: 12rem;
    padding-bottom: 6rem;
    background-size: cover;
}

.breadcrumb-item+.breadcrumb-item::before {
    color: #999999;
}


/*** Section Header ***/
.section-header {
    position: relative;
    padding-top: 25px;
}

.section-header::before {
    position: absolute;
    content: "";
    width: 60px;
    height: 2px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: var(--primary);
}

.section-header::after {
    position: absolute;
    content: "";
    width: 90px;
    height: 2px;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background: var(--secondary);
}

.section-header.text-start::before,
.section-header.text-start::after {
    left: 0;
    transform: translateX(0);
}



/*** About ***/
.about-img img {
    position: relative;
    z-index: 2;
}

.about-img::before {
    position: absolute;
    content: "";
    top: 0;
    left: -50%;
    width: 100%;
    height: 100%;
    background-image: -webkit-repeating-radial-gradient(#FFFFFF, #EEEEEE 5px, transparent 5px, transparent 10px);
    background-image: -moz-repeating-radial-gradient(#FFFFFF, #EEEEEE 5px, transparent 5px, transparent 10px);
    background-image: -ms-repeating-radial-gradient(#FFFFFF, #EEEEEE 5px, transparent 5px, transparent 10px);
    background-image: -o-repeating-radial-gradient(#FFFFFF, #EEEEEE 5px, transparent 5px, transparent 10px);
    background-image: repeating-radial-gradient(#FFFFFF, #EEEEEE 5px, transparent 5px, transparent 10px);
    background-size: 20px 20px;
    transform: skew(20deg);
    z-index: 1;
}


/*** Product ***/
.nav-pills .nav-item .btn {
    color: var(--dark);
}

.nav-pills .nav-item .btn:hover,
.nav-pills .nav-item .btn.active {
    color: #FFFFFF;
}

.product-item {
    box-shadow: 0 0 45px rgba(0, 0, 0, .07);
}

.product-item img {
    transition: .5s;
}

.product-item:hover img {
    transform: scale(1.1);
}

.product-item small a:hover {
    color: var(--primary) !important;
}



/*** Footer ***/
.footer {
    color: #999999;
}

.footer .btn.btn-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: #999999;
    font-weight: normal;
    text-transform: capitalize;
    transition: .3s;
}

.footer .btn.btn-link::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: #999999;
    margin-right: 10px;
}

.footer .btn.btn-link:hover {
    color: var(--light);
    letter-spacing: 1px;
    box-shadow: none;
}

.footer .copyright {
    padding: 25px 0;
    font-size: 15px;
    border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
    color: var(--light);
}

.footer .copyright a:hover {
    color: var(--primary);
}